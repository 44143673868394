import React from "react"

import { navigate } from "gatsby"

import propTypes from "prop-types"

import close from "../../assets/images/close-menu.svg"
import { AuthenticationContext } from "../../context/"
import { PriceList } from "../../utils/price-list"

import "./PricingInfo.scss"

const PricingInfo = ({ setIsInfoOpen }) => {
  const { checkIsAuthenticated } = React.useContext(AuthenticationContext)

  React.useEffect(() => {
    checkIsAuthenticated()
  }, [])

  const back = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <div className="pricing-info-container">
      <div className="pricing-info">
        <div className="pricing-info__close">
          <span
            onClick={back}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                back()
              }
            }}
          >
            <img src={close} />
          </span>
        </div>
        <div className="pricing-info__options">
          <div className="pricing-info__options__container">
            <div className="service-types">
              <h3>Dry Cleaning</h3>
              <div className="service-types__container">
                {PriceList.DryCleaningList.map((data, i) => (
                  <div className="item-price" key={i}>
                    <p>{data.item}</p>
                    <div>
                      <p>{data.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pricing-info__options__container">
            <div className="service-types">
              <h3>Wash and Press</h3>
              <div className="service-types__container">
                {PriceList.WashAndPressList.map((data, i) => (
                  <div className="item-price" key={i}>
                    <p>{data.item}</p>
                    <div>
                      <p>{data.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="service-types">
              <h3>Wash and Fold</h3>
              <div className="service-types__container">
                {PriceList.WashAndFold.map((data, i) => (
                  <div className="item-price" key={i}>
                    <p>{data.item}</p>
                    <div>
                      <p>{data.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pricing-info__options__container">
            <div className="service-types">
              <h3>Press Only</h3>
              <div className="service-types__container">
                {PriceList.PressOnly.map((data, i) => (
                  <div className="item-price" key={i}>
                    <p>{data.item}</p>
                    <div>
                      <p>{data.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="email-link">
          <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
        </div>
      </div>
    </div>
  )
}

PricingInfo.propTypes = {
  setIsInfoOpen: propTypes.func.isRequired,
}

export default PricingInfo
