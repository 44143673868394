exports.PriceList = {
  DryCleaningList: [
    {
      item: "2-Piece Suit",
      price: "$20.00",
    },
    {
      item: "Dress Suit",
      price: "$25.00",
    },
    {
      item: "Dinner Suit",
      price: "$26.00",
    },
    {
      item: "Jacket/Blazer",
      price: "$12.00",
    },
    {
      item: "Trousers",
      price: "$10.00",
    },
    {
      item: "Shorts",
      price: "$10.00",
    },
    {
      item: "Plain Skirts",
      price: "$10.00",
    },
    {
      item: "Pleated Skirt",
      price: "$18.00",
    },
    {
      item: "Blouse",
      price: "$9.00",
    },
    {
      item: "Vest",
      price: "$9.00",
    },
    {
      item: "Sweater/Jumper",
      price: "$10.00",
    },
    {
      item: "Scarf",
      price: "$10.00",
    },
    {
      item: "Tie",
      price: "$8.00",
    },
    {
      item: "Shirt",
      price: "$6.00",
    },
    {
      item: "Midi Dress",
      price: "$15.00",
    },
    {
      item: "Maxi Dress",
      price: "$22.00",
    },
    {
      item: "Cocktail Dress",
      price: "$20.00",
    },
    {
      item: "Full Length Coat",
      price: "$25.00",
    },
  ],
  PressOnly: [
    {
      item: "Shirt",
      price: "$3.00",
    },
    {
      item: "T-Shirt",
      price: "$3.50",
    },
    {
      item: "Polo",
      price: "$4.00",
    },
    {
      item: "Trousers/Jeans",
      price: "$8.00",
    },
    {
      item: "Shorts",
      price: "$6.00",
    },
    {
      item: "Skirt",
      price: "$8.00",
    },
    {
      item: "Blouse",
      price: "$8.00",
    },
    {
      item: "Sweater/Cardigan",
      price: "$7.00",
    },
    {
      item: "Midi Dress",
      price: "$12.00",
    },
    {
      item: "Maxi Dress",
      price: "$16.00",
    },
    {
      item: "Cocktail Dress",
      price: "$15.00",
    },
  ],
  WashAndFold: [
    {
      item: "Per Kg",
      price: "$5.00",
    },
    {
      item: "Doona",
      price: "$25.00-$40.00",
    },
    {
      item: "Pillow",
      price: "$15.00",
    },
    {
      item: "Mattress Protector",
      price: "$25.00-$40.00",
    },
  ],
  WashAndPressList: [
    {
      item: "Shirt",
      price: "$4.00",
    },
    {
      item: "Shorts",
      price: "$8.00",
    },
    {
      item: "Skirt",
      price: "$10.00",
    },
    {
      item: "Trousers/Jeans",
      price: "$10.00",
    },
  ],
}
