import React from "react"

import PriceInfo from "../components/Application/PricingInfo"

const Pricing = () => {
  return (
    <div>
      <PriceInfo />
    </div>
  )
}

export default Pricing
